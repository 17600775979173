import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import { BLOK_ARRAY } from "../../../prop_types";
import * as classes from "./source_code_window.module.scss";
import SourceCodeTab from "./sub_components/source_code_tab/source_code_tab";

export default function SourceCodeWindow({ tabs, color }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabRef = useRef();

  const classNames = [classes.sourceCodeWindow, classes[`color_${color}`]];

  useEffect(() => {
    // Ensure tab is scrolled to top when chaging active tab
    if (tabRef.current) {
      tabRef.current.scrollTop = 0;
    }
  }, [activeTabIndex]);

  useEffect(() => {
    setActiveTabIndex(0);
  }, [tabs]);

  return (
    <div
      className={classes.sourceCodeWrapper}
      data-testid="source-code-wrapper"
    >
      <div className={classNames.join(" ")}>
        <div className={classes.tabs}>
          {tabs &&
            tabs.length > 0 &&
            tabs.map((tab, i) => {
              const className =
                i === activeTabIndex
                  ? `${classes.button} ${classes.isActive}`
                  : classes.button;
              if (tab.title) {
                return (
                  <SbEditable content={tab} key={tab._uid}>
                    <div className={classes.tabButtonWrapper}>
                      <button
                        type="button"
                        className={className}
                        onClick={() => {
                          setActiveTabIndex(i);
                        }}
                      >
                        {tab.title}
                      </button>
                    </div>
                  </SbEditable>
                );
              }

              return null;
            })}
        </div>
        <div className={classes.tab} ref={tabRef}>
          {tabs && tabs.length && (
            <SourceCodeTab {...{ ...tabs[activeTabIndex], color }} />
          )}
        </div>
      </div>
    </div>
  );
}

SourceCodeWindow.defaultProps = {
  tabs: [],
};

SourceCodeWindow.propTypes = {
  tabs: BLOK_ARRAY,
  color: PropTypes.oneOf(["purple", "blue"]).isRequired,
};

import React from "react";
import GridNew from "~components/atoms/grid_new/grid_new";
import Image from "~components/atoms/image/image";
import { BLOK_ARRAY, RESPONSIVE_VALUE_NEW } from "../../../prop_types";

function ImageGrid({ split, images: imagesArray }) {
  if (imagesArray?.length > 0) {
    return (
      <GridNew split={split}>
        {imagesArray.map((image) => {
          return <Image {...image} key={image._uid} testId="image-grid-item" />;
        })}
      </GridNew>
    );
  }
  return null;
}

ImageGrid.propTypes = {
  images: BLOK_ARRAY,
  split: RESPONSIVE_VALUE_NEW,
};

ImageGrid.defaultProps = {
  images: null,
  split: null,
};

export default ImageGrid;

import React from "react";
import PropTypes from "prop-types";
import Heading from "~components/atoms/heading/heading";
import RichText from "~components/molecules/rich_text/rich_text";
import TextLink from "~components/molecules/text_link/text_link";
import { COLOR, LINK, MEDIA, RICH_TEXT } from "../../../prop_types";
import FeatureGridIcon from "./feature_grid_icon";
import {
  featureGridItemStyle,
  textStyle,
} from "./feature_grid_item.module.scss";

export default function FeatureGridItem({
  hasCard,
  image,
  title,
  text,
  textColor,
  ctaText,
  ctaLink,
}) {
  return (
    <li className={featureGridItemStyle} data-testid="feature-grid-item">
      <FeatureGridIcon hasCard={hasCard} image={image} />
      {title && title.length > 0 && (
        <Heading seoLevel="4" visualLevel={6} color={textColor}>
          {title}
        </Heading>
      )}
      {text && (
        <div className={textStyle}>
          <RichText color={textColor} text={text} balanceText />
        </div>
      )}
      {ctaText && ctaLink && (
        <TextLink title={ctaText} link={ctaLink} data-testid="text-link" />
      )}
    </li>
  );
}

FeatureGridItem.defaultProps = {
  hasCard: false,
  image: null,
  title: "Title",
  text: "Lorem ipsum dolor demit",
  textColor: null,
  ctaText: null,
  ctaLink: null,
};

FeatureGridItem.propTypes = {
  hasCard: PropTypes.bool,
  image: MEDIA,
  title: PropTypes.string,
  text: RICH_TEXT,
  textColor: COLOR,
  ctaText: PropTypes.string,
  ctaLink: LINK,
};

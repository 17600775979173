import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import GridNew from "~components/atoms/grid_new/grid_new";
import Heading from "~components/atoms/heading/heading";
import Image from "~components/atoms/image/image";
import Section from "~components/atoms/section/section";
import CtaResponsive from "~components/molecules/cta_responsive/cta_responsive";
import HeroPromoCard from "~components/molecules/hero_promo_card/hero_promo_card";
import HorizontalDivider from "~components/molecules/horizontal_divider/horizontal_divider";
import RichText from "~components/molecules/rich_text/rich_text";
import { BLOK_ARRAY, RESPONSIVE_VALUE_NEW } from "../../../prop_types";
import DynamicArtworkComponent from "../../utils/dynamic_artwork_component/dynamic_artwork_component";
import * as classes from "./section_hero_wrapper.module.scss";

function SectionHeroWrapper({
  artwork: artworkArray,
  bodyRichText: bodyRichTextArray,
  heroPromoCard: heroPromoCardArray,
  cta: ctaArray,
  heading,
  headingOpacity,
  headingVisualLevel,
  id,
  isReversed,
  logos,
  logosGridSplit,
  logosTitle,
  sectionSettings: sectionSettingsArray,
  subscriptHeading,
  ...rest
}) {
  const [artwork] = artworkArray || [];
  const [bodyRichText] = bodyRichTextArray || [];
  const [cta] = ctaArray || [];
  const [heroPromoCard] = heroPromoCardArray || [];
  const [sectionSettings] = sectionSettingsArray || [];

  const classNames = [
    classes.heroWrapper,
    isReversed ? classes.isReversed : "",
  ];

  return (
    <Section id={id} sectionSettings={sectionSettings} {...rest}>
      <Contain>
        <div className={classNames.join(" ")}>
          {/* ———— ARTWORK ————— */}
          {artwork && (
            <div className={classes.artworkWrapper}>
              <DynamicArtworkComponent artwork={artwork} />
            </div>
          )}

          <header className={classes.copyWrapper}>
            {/* ———— HEADINGS ————— */}
            {subscriptHeading && (
              <Heading
                {...rest}
                color="white"
                visualLevel={1}
                seoLevel={1}
                opacity={0.5}
                balanceText
                noMargin
              >
                {subscriptHeading}
              </Heading>
            )}
            {heading && (
              <Heading
                {...rest}
                color="white"
                visualLevel={1}
                seoLevel={subscriptHeading ? 2 : 1}
                opacity={headingOpacity}
              >
                {heading}
              </Heading>
            )}

            {/* ———— RICH TEXT ————— */}
            {bodyRichText && <RichText color="white" {...bodyRichText} />}

            {/* ———— CTA RESPONSIVE ————— */}
            {cta && <CtaResponsive {...cta} />}

            {/* ———— PROMO CARD ————— */}
            {heroPromoCard && <HeroPromoCard {...heroPromoCard} />}

            {/* ———— LOGOS ————— */}
            {logosTitle && (
              <HorizontalDivider text={logosTitle} variant="white" noMargin />
            )}
            {logos?.length > 0 && (
              <GridNew split={logosGridSplit}>
                {logos.map((logo) => {
                  return <Image {...logo} key={logo._uid} />;
                })}
              </GridNew>
            )}
          </header>
        </div>
      </Contain>
    </Section>
  );
}

SectionHeroWrapper.propTypes = {
  artwork: BLOK_ARRAY,
  bodyRichText: BLOK_ARRAY,
  heroPromoCard: BLOK_ARRAY,
  cta: BLOK_ARRAY,
  heading: PropTypes.string,
  headingOpacity: PropTypes.string,
  headingVisualLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  isReversed: PropTypes.bool,
  logos: BLOK_ARRAY,
  logosGridSplit: RESPONSIVE_VALUE_NEW,
  logosTitle: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
  subscriptHeading: PropTypes.string,
};

SectionHeroWrapper.defaultProps = {
  artwork: null,
  bodyRichText: null,
  heroPromoCard: null,
  cta: null,
  heading: null,
  headingOpacity: null,
  headingVisualLevel: 1,
  id: null,
  isReversed: false,
  logos: null,
  logosGridSplit: null,
  logosTitle: null,
  sectionSettings: null,
  subscriptHeading: null,
};

export default SectionHeroWrapper;

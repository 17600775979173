// extracted by mini-css-extract-plugin
export var backgroundImage = "background_image-module--backgroundImage--c6-v6";
export var horizontalAlign_center = "background_image-module--horizontalAlign_center--QwqJE";
export var horizontalAlign_left = "background_image-module--horizontalAlign_left--2Vp8u";
export var horizontalAlign_right = "background_image-module--horizontalAlign_right--YM9+T";
export var isWidth = "background_image-module--isWidth--e81r9";
export var objectFit_contain = "background_image-module--objectFit_contain--L3i0k";
export var objectFit_cover = "background_image-module--objectFit_cover--jYu+U";
export var roundBottom = "background_image-module--roundBottom--IjMgd";
export var roundTop = "background_image-module--roundTop--dzuFl";
export var verticalAlign_bottom = "background_image-module--verticalAlign_bottom--dZMC5";
export var verticalAlign_middle = "background_image-module--verticalAlign_middle--6Jmb0";
export var verticalAlign_top = "background_image-module--verticalAlign_top--3eBz3";
export var verticalalign_bottom = "background_image-module--verticalalign_bottom--d6MZj";
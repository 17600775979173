import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import GridNew from "~components/atoms/grid_new/grid_new";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import CtaResponsive from "~components/molecules/cta_responsive/cta_responsive";
import QuoteSmallCenterAligned from "~components/molecules/quote/quote_small_center_aligned/quote_small_center_aligned";
import RichText from "~components/molecules/rich_text/rich_text";
import SourceCodeWindow from "~components/organisms/source_code_window/source_code_window";
import useMatchMedia from "~hooks/use_match_media/use_match_media";
import { BLOK_ARRAY, RESPONSIVE_VALUE_NEW } from "../../../prop_types";

function SectionDeveloperCodeWithQuotes({
  bodyRichText: bodyRichTextArray,
  codeSample: codeSampleArray,
  cta: ctaArray,
  heading,
  id,
  quoteGridSplit,
  quotes: quotesArray,
  sectionSettings: sectionSettingsArray,
  subscriptHeading,
  ...rest
}) {
  const isMobileWindow = useMatchMedia({ max: "L" });
  const [bodyRichText] = bodyRichTextArray || [];
  const [codeSample] = codeSampleArray || [];
  const [cta] = ctaArray || [];
  const [sectionSettings] = sectionSettingsArray || [];

  return (
    <Section id={id} sectionSettings={sectionSettings} {...rest}>
      <Contain small>
        {subscriptHeading && (
          <Heading
            color="white"
            opacity={0.5}
            isCentered
            isUppercase
            visualLevel={7}
            seoLevel={3}
            {...rest}
          >
            {subscriptHeading}
          </Heading>
        )}
        {heading && (
          <Heading
            color="white"
            isCentered
            visualLevel={2}
            seoLevel={4}
            {...rest}
          >
            {heading}
          </Heading>
        )}

        {/* ————— BODY TEXT ————— */}
        {bodyRichText && <RichText {...bodyRichText} />}
      </Contain>

      <Contain>
        {/* ———— CODE SAMPLE ————— */}
        {!isMobileWindow && codeSample && <SourceCodeWindow {...codeSample} />}
        {/* ———— QUOTES GRID ————— */}
        {quotesArray.length > 0 && (
          <GridNew split={quoteGridSplit}>
            {quotesArray.map((quote) => {
              return <QuoteSmallCenterAligned {...quote} key={quote._uid} />;
            })}
          </GridNew>
        )}
        {/* ———— CTA ————— */}
        {cta && <CtaResponsive {...cta} />}
      </Contain>
    </Section>
  );
}

SectionDeveloperCodeWithQuotes.propTypes = {
  bodyRichText: BLOK_ARRAY,
  codeSample: BLOK_ARRAY,
  cta: BLOK_ARRAY,
  heading: PropTypes.string,
  id: PropTypes.string,
  quoteGridSplit: RESPONSIVE_VALUE_NEW,
  quotes: BLOK_ARRAY,
  ratio: PropTypes.oneOf(["6_6", "5_7", "4_8"]),
  sectionSettings: BLOK_ARRAY,
  subscriptHeading: PropTypes.string,
  transparentVideoRetina: BLOK_ARRAY,
};

SectionDeveloperCodeWithQuotes.defaultProps = {
  bodyRichText: null,
  codeSample: null,
  cta: null,
  heading: null,
  id: null,
  quoteGridSplit: null,
  quotes: null,
  ratio: "6_6",
  sectionSettings: null,
  subscriptHeading: null,
  transparentVideoRetina: null,
};

export default SectionDeveloperCodeWithQuotes;

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import useSettingsClassNames from "~hooks/use_settings_classnames/use_settings_classnames";
import { BLOK_ARRAY } from "../../../prop_types";
import {
  cardStyle,
  hoverStateStyle,
  isFullWidthStyle,
  isRaisedStyle,
  overflowHiddenStyle,
  stretchStyle,
} from "./card.module.scss";

export default function Card({
  children,
  settings,
  hoverState,
  block,
  isFullWidth,
  isRaised,
  overflowHidden,
  stretch,
}) {
  const [settingsClassNames] = useSettingsClassNames(settings);

  const cardClassNames = classNames(cardStyle, ...settingsClassNames, {
    [overflowHiddenStyle]: overflowHidden,
    [hoverStateStyle]: hoverState,
    [isRaisedStyle]: isRaised,
    [stretchStyle]: stretch,
    [isFullWidthStyle]: isFullWidth || block,
  });

  return <div className={cardClassNames}>{children}</div>;
}

Card.defaultProps = {
  children: null,
  settings: null,
  isRaised: false,
  isFullWidth: false,
  block: false,
  overflowHidden: true,
  hoverState: false,
  stretch: false,
};

Card.propTypes = {
  children: PropTypes.oneOfType([BLOK_ARRAY, PropTypes.node]),
  settings: BLOK_ARRAY,
  isRaised: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  block: PropTypes.bool,
  overflowHidden: PropTypes.bool,
  hoverState: PropTypes.bool,
  stretch: PropTypes.bool,
};

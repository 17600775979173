import React from "react";
import PropTypes from "prop-types";
import Column from "~components/atoms/column/column";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Image from "~components/atoms/image/image";
import Section from "~components/atoms/section/section";
import Form from "~components/organisms/form/form";
import { BLOK_ARRAY, MEDIA } from "../../../prop_types";
import * as classes from "./section_contact_form.module.scss";

const COLUMN_SPAN = { XXL: "6", M: "8", XS: "10" };

function SectionContactForm({
  form: formArray,
  id,
  imageLeft,
  imageRight,
  sectionSettings: sectionSettingsArray,
  subtitle,
  title,
  ...rest
}) {
  const [form] = formArray || [];
  const [sectionSettings] = sectionSettingsArray || [];

  return (
    <Section id={id} sectionSettings={sectionSettings} {...rest}>
      <Contain isContentCentered>
        <div className={classes.imageLeft}>
          <Image image={imageLeft} width="300" />
        </div>
        <div className={classes.imageRight}>
          <Image image={imageRight} width="300" />
        </div>

        <div className={classes.formWrapper}>
          <Column span={COLUMN_SPAN}>
            {title && (
              <Heading
                {...rest}
                balanceText
                color="white"
                isCentered
                seoLevel={3}
                visualLevel={3}
              >
                {title}
              </Heading>
            )}
            {subtitle && (
              <div className={classes.subtitle}>
                <Heading
                  {...rest}
                  balanceText
                  color="white"
                  isCentered
                  seoLevel={4}
                  visualLevel={6}
                >
                  {subtitle}
                </Heading>
              </div>
            )}
            {form && <Form {...form} />}
          </Column>
        </div>
      </Contain>
    </Section>
  );
}

SectionContactForm.propTypes = {
  form: BLOK_ARRAY,
  id: PropTypes.string,
  imageLeft: MEDIA,
  imageRight: MEDIA,
  sectionSettings: BLOK_ARRAY,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

SectionContactForm.defaultProps = {
  form: null,
  id: null,
  imageLeft: null,
  imageRight: null,
  sectionSettings: null,
  subtitle: "Talk to an expert",
  title: "Ready to get started?",
};

export default SectionContactForm;

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Heading from "~components/atoms/heading/heading";
import HorizontalDivider from "~components/molecules/horizontal_divider/horizontal_divider";
import getTitleSectionColors from "./helper_functions/get_title_section_colors";

export default function TitleSection({
  title,
  subtitle,
  subscriptTitle,
  color,
  isCentered,
  hasHorizontalDivider,
}) {
  const titleSectionColors = useMemo(() => {
    return getTitleSectionColors({ color });
  }, [color]);
  return (
    <>
      {subscriptTitle &&
        subscriptTitle.length > 0 &&
        (!hasHorizontalDivider ? (
          <Heading
            visualLevel={7}
            seoLevel="3"
            isUppercase
            isCentered={isCentered}
            color={titleSectionColors.secondaryColor}
            balanceText
          >
            {subscriptTitle}
          </Heading>
        ) : (
          <HorizontalDivider
            textColor={titleSectionColors.secondaryColor}
            lineColor={titleSectionColors.dividerColor}
            text={subscriptTitle}
          />
        ))}

      {title && title.length > 0 && (
        <Heading
          visualLevel="3"
          seoLevel="4"
          isCentered={isCentered}
          color={titleSectionColors.primaryColor}
          balanceText
        >
          {title}
        </Heading>
      )}
      {subtitle && subtitle.length > 0 && (
        <Heading
          visualLevel={6}
          seoLevel="5"
          isCentered={isCentered}
          color={titleSectionColors.secondaryColor}
          balanceText
        >
          {subtitle}
        </Heading>
      )}
    </>
  );
}

TitleSection.defaultProps = {
  title: null,
  subtitle: null,
  subscriptTitle: null,
  color: "cloud_burst",
  isCentered: false,
  hasHorizontalDivider: false,
};

TitleSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subscriptTitle: PropTypes.string,
  color: PropTypes.string,
  isCentered: PropTypes.bool,
  hasHorizontalDivider: PropTypes.bool,
};

import PropTypes from "prop-types";
import React from "react";

import Card from "~components/atoms/card/card";
import Image from "~components/atoms/image/image";

import { MEDIA } from "../../../prop_types";
import { iconWrapperStyle } from "./feature_grid_icon.module.scss";

export default function FeatureGridIcon({ hasCard, image }) {
  if (hasCard) {
    return (
      <div className={iconWrapperStyle}>
        <Card>
          <Image image={image} width={64} />
        </Card>
      </div>
    );
  }
  return (
    <div className={iconWrapperStyle}>
      <Image image={image} width={64} />
    </div>
  );
}

FeatureGridIcon.defaultProps = {
  hasCard: false,
  image: null,
};

FeatureGridIcon.propTypes = {
  hasCard: PropTypes.bool,
  image: MEDIA,
};

import React from "react";
import PropTypes from "prop-types";
import * as classes from "./aspect_ratio.module.scss";

const AspectRatio = ({ children, ratio }) => {
  const style = {
    paddingTop: `${ratio * 100}%`,
  };

  if (!ratio) {
    return children;
  }

  return (
    <div className={classes.aspectRatio} style={style}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

AspectRatio.defaultProps = {
  children: null,
  ratio: null,
};

AspectRatio.propTypes = {
  children: PropTypes.node,
  ratio: PropTypes.number,
};

export default AspectRatio;

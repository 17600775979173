export default function getTitleSectionColors({ color }) {
  if (color === "cloud_burst") {
    return {
      primaryColor: { color: "rgba(9,53,84,1)" },
      secondaryColor: { color: "rgba(9,53,84,0.5)" },
      dividerColor: { color: "rgba(9,53,84,0.25)" },
    };
  }

  return {
    primaryColor: { color: "rgba(255,255,255,1)" },
    secondaryColor: { color: "rgba(255,255,255,0.75)" },
    dividerColor: { color: "rgba(255,255,255,0.5)" },
  };
}

import React from "react";
import PropTypes from "prop-types";
import Section from "~components/atoms/section/section";
import FeatureGrid from "~components/organisms/feature_grid/feature_grid";
import { BLOK_ARRAY } from "../../../prop_types";

function SectionFeatureGrid({
  featureGrid: featureGridArray,
  id,
  sectionSettings: sectionSettingsArray,
  ...rest
}) {
  const [featureGrid] = featureGridArray || [];
  const [sectionSettings] = sectionSettingsArray || [];

  if (featureGrid) {
    return (
      <Section id={id} sectionSettings={sectionSettings} {...rest}>
        <FeatureGrid {...featureGrid} />
      </Section>
    );
  }

  return null;
}

SectionFeatureGrid.propTypes = {
  featureGrid: BLOK_ARRAY,
  id: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
};

SectionFeatureGrid.defaultProps = {
  featureGrid: null,
  id: null,
  sectionSettings: null,
};

export default SectionFeatureGrid;

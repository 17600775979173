/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import Contain from "~components/atoms/contain/contain";
import GridNew from "~components/atoms/grid_new/grid_new";
import CtaResponsive from "~components/molecules/cta_responsive/cta_responsive";
import TitleSection from "~components/organisms/title_section/title_section";
import { BLOK_ARRAY, RESPONSIVE_VALUE } from "../../../prop_types";
import {
  gridWrapperStyle,
  headingWrapperStyle,
} from "./feature_grid.module.scss";
import FeatureGridItem from "./feature_grid_item";

export default function FeatureGrid({
  children,
  title,
  subtitle,
  subscriptTitle,
  cta: ctaArray,
  color,
  isCentered,
  hasHorizontalDivider,
  split,
  ...rest
}) {
  const [cta] = ctaArray || [];
  return (
    <SbEditable content={rest}>
      <section data-testid="feature-grid">
        <Contain>
          <div className={headingWrapperStyle}>
            {/* ToDo: Simplify unnecessarily complicated abstraction in feature grid */}
            <TitleSection
              title={title}
              subtitle={subtitle}
              subscriptTitle={subscriptTitle}
              isCentered={isCentered}
              color={color}
              hasHorizontalDivider={hasHorizontalDivider}
            />
          </div>

          <div className={gridWrapperStyle}>
            <GridNew split={split}>
              {children.map((item) => {
                return (
                  <SbEditable content={item} key={item._uid}>
                    <FeatureGridItem {...item} />
                  </SbEditable>
                );
              })}
            </GridNew>
          </div>
          {cta && <CtaResponsive {...cta} />}
        </Contain>
      </section>
    </SbEditable>
  );
}

FeatureGrid.defaultProps = {
  children: null,
  subscriptTitle: null,
  title: null,
  subtitle: null,
  color: null,
  cta: null,
  isCentered: false,
  hasHorizontalDivider: false,
  split: { desktop: "3", tablet: "2", mobile: "1" },
};

FeatureGrid.propTypes = {
  subscriptTitle: PropTypes.string,
  split: RESPONSIVE_VALUE,
  children: BLOK_ARRAY,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cta: BLOK_ARRAY,
  color: PropTypes.oneOf(["cloud_burst", "white"]),
  isCentered: PropTypes.bool,
  hasHorizontalDivider: PropTypes.bool,
};

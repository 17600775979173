import React from "react";
import PropTypes from "prop-types";
import Card from "~components/atoms/card/card";
import Image from "~components/atoms/image/image";
import { BLOK_ARRAY, MEDIA, RICH_TEXT } from "../../../prop_types";
import RichText from "../rich_text/rich_text";
import { wrapperStyle } from "./hero_promo_card.module.scss";

function HeroPromoCard({ image: [image], richText: [richText] }) {
  return (
    <Card>
      <div className={wrapperStyle}>
        {image && <Image {...image} />}
        {richText && <RichText {...richText} />}
      </div>
    </Card>
  );
}

HeroPromoCard.propTypes = {
  image: PropTypes.arrayOf(MEDIA),
  richText: PropTypes.arrayOf(RICH_TEXT),
  settings: BLOK_ARRAY,
};

HeroPromoCard.defaultProps = {
  image: [],
  richText: [],
  settings: null,
};

export default HeroPromoCard;

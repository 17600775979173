import React from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import Image from "~components/atoms/image/image";
import useSettingsClassNames from "~hooks/use_settings_classnames/use_settings_classnames";
import { BLOK_ARRAY, MEDIA } from "../../../prop_types";
import * as classes from "./background_image.module.scss";

export default function BackgroundImage({
  image,
  width,
  objectFit,
  verticalAlign,
  horizontalAlign,
  settings,
  roundTop,
  roundBottom,
  ...rest
}) {
  const [settingsClassNames] = useSettingsClassNames(settings);

  const classNames = [
    classes.backgroundImage,
    classes[`verticalAlign_${verticalAlign}`],
    classes[`horizontalAlign_${horizontalAlign}`],
    ...settingsClassNames,
  ];

  if (width) {
    classNames.push(classes.isWidth);
  } else {
    classNames.push(classes[`objectFit_${objectFit}`]);
  }

  if (roundTop) {
    classNames.push(classes.roundTop);
  }
  if (roundBottom) {
    classNames.push(classes.roundBottom);
  }

  return (
    <SbEditable content={rest}>
      <div className={classNames.join(" ")}>
        <Image
          isAboveTheFold
          objectFit={objectFit}
          image={image}
          width={width}
        />
      </div>
    </SbEditable>
  );
}

BackgroundImage.defaultProps = {
  roundTop: false,
  roundBottom: false,
  image: null,
  width: null,
  settings: null,
  objectFit: "contain",
  verticalAlign: "middle",
  horizontalAlign: "center",
};

BackgroundImage.propTypes = {
  image: MEDIA,
  roundTop: PropTypes.bool,
  roundBottom: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  objectFit: PropTypes.oneOf(["cover", "contain"]),
  verticalAlign: PropTypes.oneOf(["top", "middle", "bottom"]),
  horizontalAlign: PropTypes.oneOf(["left", "center", "right"]),
  settings: BLOK_ARRAY,
};

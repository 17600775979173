import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import Image from "~components/atoms/image/image";
import Avatar from "~components/molecules/avatar/avatar";
import BalanceTextComponent from "~components/utils/balance_text_component/balance_text_component";
import SVGIcon from "~components/utils/svg_icon/svg_icon";
import { BLOK_ARRAY } from "../../../../prop_types";
import {
  authorNameStyle,
  quoteAttributionStyle,
  quoteBodyStyle,
  textColorDarkStyle,
  textColorLightStyle,
} from "./quote_small_center_aligned.module.scss";

const ICON_COLOR_ENUM = {
  light: "white",
  dark: "cloudBurst50",
  default: "cloudBurst50",
};
export default function QuoteSmallCenterAligned({
  quoteBody,
  quoteAttribution: quoteAttributionArray,
  theme,
  ...rest
}) {
  const [quoteAttribution] = quoteAttributionArray || [];
  const quoteBodyClassNames = classNames(quoteBodyStyle, {
    [textColorDarkStyle]: theme === "dark",
    [textColorLightStyle]: theme === "light",
  });

  if (quoteBody || quoteAttribution) {
    return (
      <SbEditable content={rest}>
        <blockquote className={quoteBodyClassNames} data-testid="quote">
          {quoteBody && (
            <>
              <SVGIcon
                icon="icon_quote"
                width={48}
                height={48}
                color={ICON_COLOR_ENUM[theme || "default"]}
              />
              <BalanceTextComponent>{quoteBody}</BalanceTextComponent>
            </>
          )}
          {quoteAttribution && (
            <SbEditable content={{ ...quoteAttribution }}>
              <div
                className={quoteAttributionStyle}
                data-testid="quote-attribution"
              >
                {quoteAttribution.authorAvatar.length > 0 && (
                  <Avatar {...quoteAttribution.authorAvatar[0]} width={64} />
                )}

                {quoteAttribution.authorName && (
                  <div
                    className={authorNameStyle}
                    data-testid="quote-attribution-author-name"
                    itemProp="name"
                  >
                    {quoteAttribution.authorName}
                    {quoteAttribution.authorName &&
                      quoteAttribution.authorRole &&
                      ", "}
                  </div>
                )}
                {quoteAttribution.authorRole && (
                  <div
                    data-testid="quote-attribution-author-role"
                    itemProp="jobTitle"
                  >
                    {quoteAttribution.authorRole}
                  </div>
                )}
                {quoteAttribution.clientLogo.length > 0 && (
                  <div data-testid="client-logo">
                    <Image {...quoteAttribution.clientLogo[0]} width={120} />
                  </div>
                )}
              </div>
            </SbEditable>
          )}
        </blockquote>
      </SbEditable>
    );
  }
  return null;
}

QuoteSmallCenterAligned.defaultProps = {
  quoteBody: null,
  quoteAttribution: null,
  theme: "dark",
};

QuoteSmallCenterAligned.propTypes = {
  quoteBody: PropTypes.string,
  quoteAttribution: BLOK_ARRAY,
  theme: PropTypes.string,
};

import loadable from "@loadable/component";
import PropTypes from "prop-types";
import React from "react";

import LoadingIndicator from "~components/atoms/loading_indicator/loading_indicator";

import { BLOK_ARRAY } from "../../../../../prop_types";
import * as classes from "./source_code_tab.module.scss";

const SourceCode = loadable(
  () => {
    return import("../source_code/source_code");
  },
  {
    fallback: <LoadingIndicator />,
  }
);

export default function SourceCodeTab({ code, color }) {
  return (
    <div className={classes.tab}>
      {code &&
        code.length > 0 &&
        code.map((item) => {
          return <SourceCode key={item._uid} {...{ ...item, color }} />;
        })}
    </div>
  );
}

SourceCodeTab.defaultProps = {
  code: [],
  color: null,
};

SourceCodeTab.propTypes = {
  code: BLOK_ARRAY,
  color: PropTypes.string,
};

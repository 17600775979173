import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import CtaResponsive from "~components/molecules/cta_responsive/cta_responsive";
import ImageGrid from "~components/molecules/image_grid/image_grid";
import RichText from "~components/molecules/rich_text/rich_text";
import { BLOK_ARRAY, COLOR } from "../../../prop_types";
import { imageGridWrapperStyle } from "./section_image_grid.module.scss";

function SectionImageGrid({
  contentRichText: contentRichTextArray,
  gridSplit,
  heading,
  headingColor,
  id,
  images,
  ctaResponsive: ctaResponsiveArray,
  sectionSettings: sectionSettingsArray,
  ...rest
}) {
  const [contentRichText] = contentRichTextArray || [];
  const [ctaResponsive] = ctaResponsiveArray || [];
  const [sectionSettings] = sectionSettingsArray || [];
  return (
    <Section
      id={id}
      sectionSettings={sectionSettings}
      {...rest}
      data-testid="section"
    >
      <Contain>
        {heading && (
          <Heading
            {...rest}
            balanceText
            seoLevel={3}
            color={headingColor}
            visualLevel={3}
          >
            {heading}
          </Heading>
        )}
        {contentRichText && <RichText {...contentRichText} />}
        {images?.length > 0 && (
          <div className={imageGridWrapperStyle}>
            <ImageGrid images={images} split={gridSplit} />
          </div>
        )}
        {ctaResponsive && <CtaResponsive {...ctaResponsive} />}
      </Contain>
    </Section>
  );
}

SectionImageGrid.propTypes = {
  contentRichText: BLOK_ARRAY,
  ctaResponsive: BLOK_ARRAY,
  gridSplit: PropTypes.shape({}),
  heading: PropTypes.string,
  headingColor: PropTypes.oneOfType([COLOR, PropTypes.string]),
  id: PropTypes.string,
  images: BLOK_ARRAY,
  sectionSettings: BLOK_ARRAY,
};

SectionImageGrid.defaultProps = {
  contentRichText: null,
  ctaResponsive: null,
  gridSplit: null,
  heading: null,
  headingColor: null,
  id: null,
  images: null,
  sectionSettings: null,
};

export default SectionImageGrid;
